// Override default variables before the import
//$body-bg: #000;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.MuiButton-contained,
.MuiButton-outlined,
.MuiTypography-root {
  text-transform: none !important;
}
