#datepicker-field-report-1 {
   width: 200px;
   padding: 5px 5px 5px 10px;
   border: 1.6px solid #3d3d3d;
   height: 36px;
   border-radius: 8px;
   transition: background-color 0.3s;
}
#datepicker-field-report-2 {
   width: 200px;
   padding: 5px 5px 5px 10px;
   border: 1.6px solid #3d3d3d;
   height: 36px;
   border-radius: 8px;
   transition: background-color 0.3s;
}
body:has(#datepicker-field-report-1:hover) .hoverDatePicker-1 {
   color: #2d3a73;
}
body:has(#datepicker-field-report-2:hover) .hoverDatePicker-2 {
   color: #2d3a73;
}
#datepicker-field-report-1:hover {
   border: 1.6px solid #2d3a73;
   color: #2d3a73;
}
#datepicker-field-report-2:hover {
   border: 1.6px solid #2d3a73;
   color: #2d3a73;
}

.react-datepicker__calendar-icon {
   width: 30px;
   height: 36px;
   margin-right: 5px;
}

.employeesChart::-webkit-scrollbar {
   display: none;
}
#datepicker-field-report-1 {
   padding: 6px 10px 6px 35px !important;
}
#datepicker-field-report-2 {
   padding: 6px 10px 6px 35px !important;
}
.apexcharts-gridlines-horizontal {
   display: none;
}
.apexcharts-gridline {
   display: none;
}
