/*switch styles*/

div.tsheet-heder-view-switch-btn-container {
   display: table-cell;
   vertical-align: middle;
   text-align: center;
   padding-right: 10px;
}

div.tsheet-heder-view-switch-btn-container i {
   display: inline-block;
   position: relative;
   top: -9px;
}

.tsheet-heder-view-switch-btn-container label {
   font-size: 13px;
   color: #fff;
   font-weight: 500;
}

.tsheet-btn-view-mode-switch {
   display: inline-block;
   margin: 0px;
   position: relative;
}

.tsheet-btn-view-mode-switch > label.tsheet-btn-view-mode-switch-inner {
   margin: 0px;
   width: 140px;
   height: 30px;
   background: #e0e0e0;
   border-radius: 26px;
   overflow: hidden;
   position: relative;
   transition: all 0.3s ease;
   /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
   display: block;
}

.tsheet-btn-view-mode-switch > label.tsheet-btn-view-mode-switch-inner:before {
   content: attr(data-on);
   cursor: pointer;
   position: absolute;
   font-size: 12px;
   font-weight: 500;
   top: 6px;
   right: 20px;
   color: #fff;
}

.tsheet-btn-view-mode-switch > label.tsheet-btn-view-mode-switch-inner:after {
   content: attr(data-off);
   cursor: pointer;
   width: 70px;
   height: 26px;
   background: #fff;
   border-radius: 26px;
   position: absolute;
   left: 2px;
   top: 2px;
   text-align: center;
   transition: all 0.3s ease;
   box-shadow: 0px 0px 6px -2px #111;
   padding: 3px 0px;
   color: #424242;
}

.tsheet-btn-view-mode-switch input[type='checkbox'] {
   cursor: pointer;
   width: 50px;
   height: 25px;
   opacity: 0;
   position: absolute;
   top: 0;
   z-index: 1;
   margin: 0px;
}

.tsheet-btn-view-mode-switch
   input[type='checkbox']:checked
   + label.tsheet-btn-view-mode-switch-inner {
   color: #424242;
}

.tsheet-btn-view-mode-switch
   input[type='checkbox']:checked
   + label.tsheet-btn-view-mode-switch-inner:after {
   content: attr(data-on);
   left: 68px;
}

.tsheet-btn-view-mode-switch
   input[type='checkbox']:checked
   + label.tsheet-btn-view-mode-switch-inner:before {
   content: attr(data-off);
   right: auto;
   left: 20px;
}

.tsheet-btn-view-mode-switch
   input[type='checkbox']:checked
   + label.tsheet-btn-view-mode-switch-inner {
   color: #424242;
}

.tsheet-subtask-dialog-container {
   background-color: #ffffff;
   border-radius: 8px;
   -moz-box-shadow: 4px 4px 6px #535353;
   -webkit-box-shadow: 4px 4px 6px #535353;
   box-shadow: 4px 4px 6px #535353;
}

.tsheet-subtask-dialog-container-header {
   min-width: 570px;
   text-align: center;
   background-color: #ececec;
   border-top-left-radius: 8px;
   border-top-right-radius: 8px;
   color: #454545;
   font-size: 14px;
   padding: 10px 0;
   font-weight: 400;
   line-height: 20px;
   letter-spacing: 1%;
}

.tsheet-subtask-dialog-container-inner {
   display: flex;
   flex-direction: column;
   gap: 30px;
   padding: 30px;
}

.tsheet-subtask-dialog-container-inner-separator {
   width: 100%;
   height: 1px;
   background-color: #cacaca;
}

.tsheet-subtask-dialog-action-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.tsheet-subtask-dialog-title-container {
   text-align: center;
}

.tsheet-subtask-dialog-title {
   color: #000;
   font-size: 24px;
   font-weight: 600;
   line-height: 28px;
}

.tsheet-subtask-dialog-subtitle {
   color: #454545;
   font-size: 15px;
   font-weight: 600;
   line-height: 18px;
   margin-top: 10px;
}

.tsheet-subtask-dialog-row {
}

.tsheet-subtask-dialog-row > div:nth-child(1) {
   font-size: 16px;
   line-height: 19px;
   font-weight: 500;
   color: #454545;
   margin-bottom: 8px;
}

.timesheet-table-item-view0 {
   display: flex;
   flex-direction: column;
}

.timesheet-table-head-hours {
   display: flex;
   flex-direction: row;
   font-weight: 700;
}

.timesheet-selectable-table-item {
   cursor: pointer;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   -o-user-select: none;
   user-select: none;
   transition: box-shadow 0.1s ease-in-out;
}

.timesheet-selectable-table-item:hover {
   -webkit-box-shadow:
      0px 3px 3px -2px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12);
   box-shadow:
      0px 3px 3px -2px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.timesheet-row-project-info {
   margin-left: 8px;
}

.timesheet-row-project-info-name {
   font-weight: 700;
   font-size: 15px;
   line-height: 18px;
   color: #000;
}

.timesheet-row-project-info-pm {
   font-weight: 400;
   font-size: 12px;
   line-height: 14px;
   color: #000;
}

.tsheet-subtask-delete-dialog-container {
   display: flex;
   flex-direction: column;
   gap: 24px;
   padding: 32px;
   background-color: #fff;
}

.tsheet-subtask-delete-dialog-header {
   text-align: center;
}

.tsheet-subtask-delete-dialog-title {
   font-weight: 500;
   font-size: 20px;
   line-height: 24px;
   color: #000;
   padding: 8px 0px;
}

.tsheet-subtask-delete-dialog-subtitle {
   font-weight: 400;
   font-size: 15px;
   line-height: 18px;
   color: #454545;
}

@-moz-keyframes tsheetFadeInFromNone {
   0% {
      opacity: 0;
   }

   1% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }
}

@-o-keyframes tsheetFadeInFromNone {
   0% {
      opacity: 0;
   }

   1% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }
}

@keyframes tsheetFadeInFromNone {
   0% {
      opacity: 0;
   }

   1% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }
}

@-moz-keyframes tsheetCreateSubtaskBlink {
   0% {
      background-color: #def1fa;
   }
   100% {
      background-color: #8acce6;
   }
}

@-o-keyframes tsheetCreateSubtaskBlink {
   0% {
      background-color: #def1fa;
   }
   100% {
      background-color: #8acce6;
   }
}

@keyframes tsheetCreateSubtaskBlink {
   0% {
      background-color: #def1fa;
   }
   100% {
      background-color: #8acce6;
   }
}
