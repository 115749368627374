.user-form-row {
    margin-top: 5px;
}

.user-form-row-title {
    float: left;
    width: 160px;
}

.user-form-row-content {
    float: left;
    width: 260px;
}

.clear {
    clear: both;
}

.user-form-row-checkbox-content {
    float: left;
    width: 50px;
    height: 20px;
    background-color: "#800040";
}

.user-form-row-checkbox-title {
    float: left;
    width: 160px;
    height: 20px;
}

.customer-checkbox-title {
    float: left;
    width: 300px;
    height: 20px;
    font-size: 14px;
}

.customer-checkbox-content {
    float: left;
    width: 50px;
    height: 20px;
    background-color: "#800040";
}
