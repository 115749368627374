.row {
   margin-top: 5px;
}
.col {
   float: left;
}
.col-title {
   float: left;
   width: 30;
}
.clear {
   clear: both;
}
.formWrapper {
   max-width: 300;
}
.buttonContainer {
   margin-top: 30px;
}
.listWrapper {
   margin-left: 8px;
}
.spacer20 {
   height: 20px;
}
/*.datepicker{*/
/*  width:85px;*/
/*  max-width: 85px;*/
/*}*/
.datepickerExtend {
   width: 125px;
   max-width: 125px;
}
.datepickerMini {
   width: 50px;
   max-width: 50px;
}
.intervalContainer {
   margin-left: 5px;
   margin-bottom: 10px;
}
.typeDropdown {
   width: 200px;
   max-width: 350px;
   line-height: 5px;
}
.requestRow {
   margin-bottom: 20px;
}
.requestWrapper {
   margin-bottom: 15px;
   padding-right: 10px;
}
.requestRowButtonContainer {
   margin-top: 15px;
}
.react-datepicker__day--highlighted-custom-1 {
   border-radius: 0.3rem;
   background-color: hotpink;
   color: lemonchiffon;
}
.react-datepicker__day.react-datepicker__day--keyboard-selected {
   background: #eee;
   color: #111;
}
.react-datepicker__day.react-datepicker__day--selected {
   border-radius: 0.3rem;
   background: lightskyblue;
   color: lemonchiffon;
}
.react-datepicker__day.react-datepicker__day--today {
   border-radius: 0.3rem;
   background: lemonchiffon;
   color: deepskyblue;
}
