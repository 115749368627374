.datepicker-field {
   width: 100%;
   padding: 5px 5px 5px 10px;
   border-radius: 4px;
   border: 1px solid rgba(0, 0, 0, 0.23);
   height: 40px;
}

.datepicker-field .react-datepicker-wrapper {
   width: 100%;
}
.titlefield {
   width: 120px;
}
.percentfield {
   width: 40px;
   max-width: 40px;
}
.typefield {
   width: 350px;
   max-width: 350px;
   line-height: 20px;
}
.userfield {
   width: 385px;
   max-width: 800px;
   line-height: 12px;
   font-size: 12px;
}
.palette {
   --g: 20px; /* The gap between shapes*/
   --s: 50px; /* the size*/

   height: 300px;
   width: 300px;
   position: relative;
   display: inline-block;
   overflow: hidden;
   filter: url('#goo');
}
